
:global {
    * {
        box-sizing: border-box;
        font-family: sans-serif;
    }

    body {
        padding: 0;
        margin: 0;
    }
}

.root {
    width: 100vw;
    height: 100vh;

    background: #000;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 300px;
    padding: 10px;
    border-radius: 10px;

    background-color: #fff;

    input {
        border: 2px solid black;

        width: 100%;

        border-radius: 10px;

        padding: 5px 10px;

        font-size: 20px;
        line-height: 24px;
    }
}

.button {
    width: 100%;
    height: 42px;

    border-radius: 10px;

    border: 2px solid white;

    background-color: #fff;

    margin-top: 20px;

    font-size: 20px;

    cursor: pointer;

    transition: background-color .3s, color .3s, border .3s;

    &:active {
        background-color: #000;
        color: #fff;
    }
}

.buttons {
    display: flex;

    justify-content: space-between;

    margin-top: 10px;

    button {
        cursor: pointer;

        flex-grow: 1;

        border: 2px solid black;
        border-radius: 10px;

        font-size: 20px;

        &:first-child {
            background-color: black;
            

            padding: 5px 10px;

            color: white;
        }

        &:last-child {
            margin-left: 10px;

            background-color: #fff;
        }
    }
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
}

.title-stone {
    position: relative;
    width: 20px;
    height: 20px;
}

.grid {
    display: flex;
    justify-content: space-between;
}

.column {
    width: 35px;

    border-radius: 17px;

    &--active {
        composes: column;

        cursor: pointer;

        &:hover {
            background-color: rgba(#000, .1);
        }
    }

    &--default {
        composes: column;
        cursor: default;
    }
}

.cell {
    position: relative;
    border-radius: 50%;

    height: 35px;

    border: 2px solid black;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

}

.stone {
    position: absolute;

    border-radius: 50%;

    opacity: 0;

    animation: fade-in .5s ease-out forwards;
    
    &--my {
        composes: stone;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: black;
    }

    &--opponent {
        composes: stone;

        top: 5px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
    
        border: 2px solid black;
    }
}

@keyframes fade-in {
    from {
        transform: translateY(-500px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}


.line {
    position: absolute;
    top: 8px;
    height: 2px;
    width: 100%;

    background: #000;

    &--left {
        composes: line;
        left: 0px;
    
        transform: rotate(45deg);
    }

    &--right {
        composes: line;
        right: 0px;

        transform: rotate(-45deg);
    }
}

.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($color: #000000, $alpha: .8);

    display: flex;
    justify-content: center;
    align-items: center;
}